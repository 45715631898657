import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Components/Home";
import NotFound from "./Components/NotFound";
import Layout from "./Components/Layout";
import Projects from "./Components/Projects";
import About from "./Components/About";

const RouteSwitch = () => {
  return (
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route path="about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default RouteSwitch;