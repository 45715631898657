import React from "react";
import ThemeSwitcher from "./ThemeSwitcher";
import { Link } from "react-router-dom";
import "../Styles/Navbar.css"

const Navbar = () => {
  return (
    <div id="navbar-div">
      <div id="navbar-inner-div">
        <Link id='brand' to="/">
          <img id='headshot-img' src={require(`../Assets/headshot.jpg`)} alt="headshot of bryan"></img>
        </Link>
        <div id="navbar-links">
          <Link to="/">home</Link>
          <Link to="/projects">projects</Link>
          {/* <Link to="/about">about</Link> */}
          <ThemeSwitcher/>
        </div>
      </div>
    </div>
  );
};

export default Navbar;