import React from "react";
import "../Styles/Project.css"

const Project = ({proj}) => {
    return (
        <a className="project-div" href={proj.src} target="_blank" rel="noreferrer">
            <h3 className="projec-title">{proj.title}</h3>
            <img className="project-img" src={require(`../Assets/${proj.img}`)} alt="project screenshot"></img>
            <p className="project-description">{proj.description}</p>
        </a>
    )
}

export default Project;