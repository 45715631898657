import React from "react";
import Project from "./Project";
import { projects } from '../Assets/projects'
import "../Styles/Projects.css"

const Projects = () => {
  const projectArray = projects.map((proj) => {
    return (
      <Project proj={proj} />
    )
})
  return (
    <div id="projects-div">
      {projectArray}
    </div>
  );
};

export default Projects;