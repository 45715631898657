import '../Styles/Home.css';
import { Link } from "react-router-dom";
import { featuredProject } from '../Assets/projects'

const Home = () => {

  return (
    <div id="home-div">
      <div id="home-inner-div">
        <div id="home-intro-div">
          <h1>Bryan Pepitone-Perez</h1>
          <p>Software Engineer with a Bachelors degree in Computer Science and FAANG experience.</p>
        </div>
        <div id="home-content-div">
          <p>Featured project</p>
          <a id='featured-project-div' href={featuredProject.src} target="_blank" rel="noreferrer">
            <img id='featured-project-img' src={require(`../Assets/${featuredProject.img}`)} alt="featured project screenshot"></img>
          </a>
          <div id="see-more-link">
            <Link to="/projects">See more</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
