import React from "react";
import { FaGithub } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { SiTwitter } from "react-icons/si";
import "../Styles/Footer.css"

const Footer = () => {
  return (
    <div id="footer-div">
      <div id="footer-inner-div">
        <div id="footer-links-div">
          <a className="footer-link" href="https://github.com/bpepitoneperez" target="_blank" rel="noreferrer">
            <FaGithub className="contact-icon"/>
          </a>
          <a className="footer-link" href="https://www.linkedin.com/in/bpepitoneperez/" target="_blank" rel="noreferrer">
            <GrLinkedinOption className="contact-icon"/>
          </a>
          <a className="footer-link" href="https://twitter.com/bpepitoneperez" target="_blank" rel="noreferrer">
            <SiTwitter className="contact-icon"/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;