export const projects = [
    {
        title: "Arcano Games",
        img: "arcano.png",
        src: "https://arcanogames.com",
        description: "Online multiplayer web application where you can play games with your friends. Built in React using Next.js, with a Node.js backend for the game server. Currently in early Alpha."
    },
    {
        title: "Searchle",
        img: "searchle.png",
        src: "https://Searchle.dev",
        description: "Online worldle-type game where you must find the hidden character in the image, with the image and character changing every day. Built with React and Node.JS with MongoDB."
    },
    {
        title: "Unity 3D Game Prototype",
        img: "unity-3dvs-prototype.png",
        src: "https://github.com/bpepitoneperez/Unity-3D-Action-Rogue-Lite-Prototype",
        description: "Prototype for a 3D third-person action game similiar to Vampire Survivors made in Unity."
    },
    {
        title: "Chrome Theme Creator",
        img: "themeCreator.png",
        src: "https://bpepitoneperez.com/ChromeImageThemeCreator/",
        description: "Create a beautiful Google Chrome Theme! Made in Angular, not pretty but doesn't need to be :)"
    },
]

export const currentProject = projects[0]
export const featuredProject = projects[0]