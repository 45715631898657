import React from "react";
//import "../Styles/About.css"

const About = () => {
  return (
    <div id="about-div">
      Coming soon...
    </div>
  );
};

export default About;