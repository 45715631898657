import React from 'react';
import {  Outlet } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import "../Styles/Layout.css"

const Layout = () => {

  return (
    <div id='layout-div' >
      <Navbar id='navbar'/>
      <Outlet id='content' context={[]} />
      <Footer id='footer' />
    </div>
  );
  
  
}

export default Layout;